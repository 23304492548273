import * as Sentry from '@sentry/sveltekit'
import posthog from 'posthog-js'
import { browser, dev } from '$app/environment'

import initApp from '$lib/init'
import { env } from '$env/dynamic/public'
import { PUBLIC_POSTHOG_PROJECT_ID, PUBLIC_SENTRY_DSN } from '$env/static/public'
import { currentBusiness, currentUser } from './lib/stores'
import { getAppVersion, getUserDisplayName, checkIfNewVersion } from './lib/utils'
import { logAppError } from '$/lib/utils'

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 0, // only for error logging for now, no profiling needed
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [
		Sentry.replayIntegration({
			// TODO: remove once out of alpha/beta
			maskAllText: false,
			blockAllMedia: false
		})
	],
	environment: env.PUBLIC_ENVIRONMENT,
	enabled: env.PUBLIC_ENVIRONMENT !== 'development'
})

if (browser && env.PUBLIC_ENVIRONMENT !== 'development') {
	posthog.init(PUBLIC_POSTHOG_PROJECT_ID, {
		api_host: 'https://eu.i.posthog.com',
		person_profiles: 'identified_only'
	})
}

currentBusiness.subscribe((business) => {
	Sentry.setContext('business', { id: business.id, name: business.name })
})

currentUser.subscribe((user) => {
	if (user) {
		Sentry.setUser({ id: user.id, username: getUserDisplayName({ user }) })
		posthog.identify(user.id, { name: getUserDisplayName({ user }) })
	} else {
		Sentry.setUser(null)
		posthog.reset()
	}
})

initApp()

let appVersion: string | null = null
if (!dev) {
	getAppVersion().then((version) => {
		appVersion = version
	})

	// reload when a new version is detected when the tab is visible again
	document.addEventListener('visibilitychange', async function () {
		if (document.visibilityState === 'visible') {
			const checkVersion = async () => {
				if (!appVersion) {
					// wait for the initial app version to be fetched
					setTimeout(checkVersion, 500)
					return
				}

				if (await checkIfNewVersion(appVersion)) {
					// TODO: remove later .. maybe replace with a dialog?
					window.location.reload()
					logAppError({
						message: 'New version detected, reloading app',
						details: { oldVersion: appVersion }
					})
				}
			}

			await checkVersion()
		}
	})
}
export const handleError = Sentry.handleErrorWithSentry()
